@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

header {
  border-bottom: 1px solid #ced4da;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.auth-bg video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
}

.auth-bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.auth-form {
  color: #fff;
  padding: 30px 40px;
  border-radius: 3px;
  position: relative;
  z-index: 99;
  background-color: #231f20;
  max-width: 730px;
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.auth-form::-webkit-scrollbar {
  width: 0;
}

.auth-form h2 {
  font-weight: 200;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  margin-bottom: 30px;
}

.auth-form input.form-control {
  border-color: rgba(255, 255, 255, 0.6);
  border-width: 2px;
  width: 300px;
  height: 50px;
  background-color: transparent;
  color: #fff;
  border-radius: 0;
  font-size: 1.25rem;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
}

@media screen and (max-width: 767px) {
  .auth-form input.form-control {
    width: 100%;
  }
}

.auth-form input.form-control::-moz-placeholder {
  color: #888;
}

.auth-form input.form-control::placeholder {
  color: #888;
}

.auth-form label {
  font-size: 14px;
}

.auth-form button {
  height: 50px;
  width: 100%;
  display: block;
  color: #fff;
  border-color: #c3262d;
  transition: all 0.5s;
}

.auth-form button:hover {
  border-color: #c3262d;
  color: #fff;
  background-color: #bbaa50;
}

.auth-form .form-check-input {
  width: 20px;
  height: 20px;
}

.login-page {
  height: 100vh;
  overflow-x: hidden;
}

.login-left {
  padding: 5rem 8rem;
}

.login-logo {
  margin-bottom: 7rem;
  max-width: 250px;
}

.login-logo img {
  width: 100%;
}

@media screen and (max-width: 1399px) {
  .login-logo {
    margin-bottom: 3rem;
  }
}

.login-title {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.login-subtitle {
  color: #a8aebb;
  font-size: 1.7rem;
  line-height: 2.5rem;
}

@media screen and (max-width: 1399px) {
  .login-left {
    padding: 2rem 3rem;
  }
}

@media screen and (max-width: 767px) {
  .login-left {
    padding: 5rem;
  }
}

@media screen and (max-width: 576px) {
  .login-left {
    padding: 5rem 3rem;
  }
}

.user-Header .fa:before,
.user-Header [class*=" fa-"]:before,
.user-Header [class^="fa-"]:before {
  color: #c3262d;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl {
  padding-left: 3rem;
}

.form-control.form-control-xl {
  font-size: 1.2rem;
  padding: 0.85rem 1rem;
}

.form-group[class*="has-icon-"].has-icon-left .form-control-icon {
  left: 5px;
}

.form-group[class*="has-icon-"].has-icon-left .form-control-eye-icon {
  right: 0;
}

.datepicker:hover {
  border: none !important;
  /* border: none !important; */
}

.form-control.form-control-xl~.form-control-icon {
  top: 0.9rem;
}

.form-control.form-control-xl~.form-control-eye-icon {
  top: 0.9rem;
}

.datepicker {
  display: block;
  width: 100%;
}

.form-group[class*="has-icon-"] .form-control-icon {
  padding: 0 0.6rem;
  position: absolute;
}

.form-group[class*="has-icon-"] .form-control-eye-icon {
  padding: 0 0.6rem;
  position: absolute;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl~.form-control-icon i {
  font-size: 1.6rem;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl~.form-control-eye-icon i {
  font-size: 1.6rem;
}

.fa:before,
[class*=" fa-"]:before,
[class^="fa-"]:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  /* font-family: bootstrap-icons !important; */
  font-style: normal;
  font-variant: normal;
  font-weight: 400 !important;
  line-height: 1;
  text-transform: none;
  vertical-align: -0.125em;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl~.form-control-icon i:before {
  color: #a6a8aa;
}

.form-group[class*="has-icon-"] .form-control.form-control-xl~.form-control-eye-icon i:before {
  color: #a6a8aa;
}

.btn-block {
  width: 100%;
}

.bg-red:focus+.bg-red,
.bg-red:focus,
.bg-red:hover {
  background-color: #b1292f !important;
  border-color: #b1292f !important;
  color: #fff !important;
}

.text-red:focus+.text-red,
.text-red:focus,
.text-red:hover {
  color: #b1292f !important;
}

.form-check-input:checked {
  background-color: #c3262d;
  border-color: #c3262d;
}

input:focus,
select:focus,
.form-select:focus .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25) !important;
  border-color: #000000 !important;
  /* margin-bottom: 20px !important; */
}

a {
  text-decoration: none;
}

.reg-text {
  position: relative;
  z-index: 99;
  color: #fff;
  margin-top: 20px;
}

.search-items-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.search-items-list li {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 7.14%;
}

@media screen and (max-width: 1399px) {
  .search-items-list li {
    width: 80px;
  }
}

@media screen and (max-width: 767px) {
  .search-items-list li {
    width: 16.66%;
  }
}

@media screen and (max-width: 576px) {
  .search-items-list li {
    width: 25%;
  }
}

@media screen and (max-width: 360px) {
  .search-items-list li {
    width: 33.33%;
  }
}

.search-items-list li input {
  position: absolute;
  opacity: 0;
}

.search-items-list li input:checked+label {
  background-color: #c3262d;
  color: #fff;
}

.search-items-list li label {
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  display: block;
  padding: 10px 3px;
  border: 1px solid #fff;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.search-items-list li label:hover {
  background-color: #fff;
}

.search-items-list li .diam-icon {
  display: block;
  font-size: 35px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .search-items-list li .diam-icon {
    font-size: 24px;
  }
}

.search-row {
  padding: 20px 0;
}

.search-head {
  background-color: #2d5599;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .search-head {
    margin-top: 24px;
  }
}

.fancy-blocks {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
}

.fancy-blocks li {
  width: 25%;
  text-align: center;
}

.fancy-blocks li img {
  width: 40px;
}

@media screen and (max-width: 767px) {
  .fancy-blocks li img {
    width: 20px;
  }
}

.fancy-blocks li input {
  position: absolute;
  opacity: 0;
}

.fancy-blocks li input:checked+label {
  background-color: #c3262d;
  color: #fff;
}

.fancy-blocks li label {
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  display: block;
  padding: 10px 3px;
  border: 1px solid #fff;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.fancy-blocks li label:hover {
  background-color: #fff;
}

.fancy-blocks li label {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .fancy-blocks li label {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.fancy-blocks li span {
  width: 50px;
  height: 50px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .fancy-blocks li span {
    width: 40px;
    height: 40px;
    margin-bottom: 4px;
  }
}

.overtone-list {
  padding-left: 0;
}

.overtone-list li {
  display: inline-block;
  width: auto;
}

.overtone-list li input {
  position: absolute;
  opacity: 0;
}

.overtone-list li input:checked+label {
  background-color: #c3262d;
  color: #fff;
}

.overtone-list li label {
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  display: block;
  padding: 10px 3px;
  border: 1px solid #fff;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.overtone-list li label:hover {
  background-color: #fff;
}

.overtone-list li label {
  padding: 7px 10px;
  background-color: #f5f5f5;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.clarity-list li {
  background-color: #f5f5f5;
}

.multiSelect .dropdown>button {
  border: 1px solid #ced4da;
}

.btn {
  padding-top: 8px;
  padding-bottom: 8px;
}

.search-accordian .accordion-header button {
  background-color: #c3262d;
  color: #fff !important;
}

.search-accordian .accordion-header button::after {
  display: none;
}

.btn-active {
  color: #c3262d;
  font-weight: 600;
}

.heading {
  font-weight: bold;
}

.user-Header {
  padding: 15px 30px;
  background-color: #fff;
  border-bottom: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .user-Header {
    padding: 10px 10px;
  }
}

.user-Header .logo {
  /* height: 50px; */
  height: 40px;
}

@media screen and (max-width: 767px) {
  .user-Header .logo {
    height: 30px;
  }
}

aside {
  left: 0;
  width: 250px;
  padding-top: 100px;
  position: fixed;
  padding: 20px 10px 20px 0;
  transition: all 0.5s;
  background-color: #fff;
  z-index: 99;
  height: 100vh;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
  top: 86px;
}

@media screen and (max-width: 991px) {
  aside {
    width: 50px;
    overflow: hidden;
  }

  aside:hover {
    width: 250px;
  }
}

aside ul {
  padding-left: 0;
  list-style: none;
}

aside ul a {
  display: block;
  font-size: 18px;
  font-weight: 300;
  color: #16294a;
  padding: 12px 10px 12px 20px;
  border-radius: 0 10px 10px 0;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  aside ul a {
    padding-left: 12px;
  }
}

aside ul a i {
  margin-right: 15px;
  color: #c3262d;
}

aside ul a:hover {
  color: #c3262d;
  background-color: #fff;
}

aside ul a.active {
  background-color: #c3262d;
  color: #fff;
}

aside ul a.active i {
  color: #fff;
}

.userMain {
  margin-left: 250px;
  background-color: #f6f6f6;
  padding: 30px;
  min-height: calc(100vh - 140px);
  margin-top: 86px;
}

@media screen and (max-width: 991px) {
  .userMain {
    margin-left: 50px;
    padding: 1px 20px;
  }
}

.profile-drp button {
  background-color: #fff !important;
  border: 0;
  color: #16294a;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .profile-drp button {
    font-size: 14px;
    padding-right: 0;
  }
}

.profile-drp button::after {
  display: none;
}

.profile-drp button .profile-icon {
  width: 40px;
  height: 40px;
  background-color: #c3262d;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.file-icon p {
  white-space: nowrap;
  padding-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .profile-drp button .profile-icon {
    width: 30px;
    height: 30px;
  }
}

.content-block {
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .content-block {
    padding: 1px 20px;
  }
}

.info-block {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 15px 30px;
}

@media screen and (max-width: 1199px) {
  .info-block {
    padding: 15px;
  }
}

@media screen and (max-width: 992px) {
  .info-block {
    padding: 10px;
  }

  .info-block h2 {
    font-size: 20px;
  }

  .info-block h6 {
    font-size: 14px;
  }
}

.k-button-solid-primary {
  background-color: #16294a;
  border-color: #16294a;
}

.k-button-solid-primary:hover {
  background-color: #223f71;
  border-color: #16294a;
}

.k-master-row.k-selected.k-table-alt-row .k-grid-content-sticky,
.k-master-row.k-selected.k-table-alt-row .k-grid-row-sticky,
.k-master-row.k-table-alt-row td.k-grid-content-sticky.k-selected,
.k-master-row.k-table-alt-row .k-table-td.k-grid-content-sticky.k-selected {
  background-color: #fbfaf5;
}

.k-grid .k-table-tbody>.k-table-row:not(.k-detail-row):hover,
.k-grid .k-table-tbody>.k-table-row:not(.k-detail-row).k-hover {
  background-color: #ededed;
}

.k-master-row.k-selected:hover .k-grid-content-sticky,
.k-master-row.k-selected:hover .k-grid-row-sticky,
.k-master-row.k-selected.k-hover .k-grid-content-sticky,
.k-master-row.k-selected.k-hover .k-grid-row-sticky,
.k-master-row:hover td.k-grid-content-sticky.k-selected,
.k-master-row.k-hover td.k-grid-content-sticky.k-selected,
.k-master-row:hover .k-table-td.k-grid-content-sticky.k-selected,
.k-master-row.k-hover .k-table-td.k-grid-content-sticky.k-selected {
  background-color: #fbfaf5;
}

.k-grid td.k-selected,
.k-grid .k-table-row.k-selected>td,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected>.k-table-td {
  background-color: #fbfaf5;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  background-color: #c3262d;
  border-color: #c3262d;
}

.k-button-flat-primary {
  color: #c3262d;
}

.dropdown-menu {
  padding: 0;
  border: 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 0;
}

.dropdown-menu .dropdown-item {
  padding: 10px 15px;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #fff;
}

.dropdown-menu .dropdown-item:active {
  background-color: transparent;
  color: inherit;
}

.dropdown-menu .dropdown-item:active i {
  color: #fff !important;
}

.searchModal {
  position: absolute;
  background-color: #fff;
  padding: 50px;
}

.detail-frame {
  height: 500px;
}

@media screen and (max-width: 767px) {
  .detail-frame {
    height: 400px;
  }
}

@media screen and (max-width: 576px) {
  .detail-frame {
    height: 300px;
  }
}

.detail-table td,
.detail-table th {
  padding: 5px;
  font-size: 14px;
}

.user-footer {
  background-color: #c3262d;
  color: #fff;
  padding: 15px;
  margin-top: 0;
}

@media screen and (max-width: 376px) {
  .navigate-buttons button {
    padding: 7px;
  }
}

.dashblock-card {
  border: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
  border-left: 7px solid #c3262d;
  margin-bottom: 1.5rem;
  color: #16294a;
  transition: all 0.5s;
}

.dashblock-card .card-body {
  padding: 24px 24px 24px 20px;
  min-height: 130px;
}

@media screen and (max-width: 767px) {
  .dashblock-card .card-body {
    min-height: 115px;
    padding: 16px;
  }
}

.dashblock-card .card-body h5 {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .dashblock-card .card-body h5 {
    font-size: 16px;
  }
}

.dashblock-card .card-body h2 {
  font-size: 40px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .dashblock-card .card-body h2 {
    font-size: 30px;
  }
}

.dashblock-card .dashblock-icon {
  position: absolute;
  right: 0;
  top: 8px;
  font-size: 100px;
  line-height: 100%;
  color: #f4f1e3;
  transition: all 0.5s;
}

@media screen and (max-width: 767px) {
  .dashblock-card .dashblock-icon {
    font-size: 75px;
  }
}

.dashblock-card:hover {
  color: #c3262d;
  background-color: #ede8d0;
}

.dashblock-card:hover .dashblock-icon {
  color: #fff;
}

/* .form-control:focus,
input:focus,
textarea:focus,
select:focus {
  border-color: #c3262d;
} */
.comparison-tbl tr:first-child td {
  border: 0;
}

.comparison-tbl tr td {
  border: 1px solid #dee2e6;
  font-weight: 600;
}

.comparison-tbl tr td img {
  height: 150px;
}

.comparison-tbl tr td:first-child {
  border-left: 0;
  width: 200px;
  font-weight: 300;
}

.comparison-tbl tr td:last-child {
  border-right: 0;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.detail-modal .modal-body {
  padding: 40px;
}

.detail-modal label {
  font-size: 14px;
}

.detail-modal h6 {
  font-weight: 600;
  margin-bottom: 20px;
}

.notFound-block h1 {
  font-size: 15rem;
  text-align: center;
  color: #16294a;
  font-style: oblique;
  font-weight: 700;
  text-shadow: 3px 3px 3px rgba(194, 179, 98, 0.9);
}

.dash-card {
  border: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-top: 2rem;
  padding: 10px;
}

.dash-card table td,
.dash-card table th {
  font-size: 14px;
  padding: 10px;
}

.dash-card table tr:last-child td {
  border-bottom: 0;
}

.notification-drp .dropdown-menu {
  min-width: 25rem;
  max-height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.notification-drp .dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

.notification-drp .dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #dee2e6;
}

.notification-drp .dropdown-menu::-webkit-scrollbar-track {
  background-color: #fff;
}

.notification-drp .dropdown-menu .dropdown-item {
  padding: 15px;
  padding-left: 40px;
  border-bottom: 1px solid #dee2e6;
  position: relative;
}

.notification-drp .dropdown-menu .dropdown-item:first-child {
  background-color: #fff !important;
  cursor: default;
  pointer-events: none;
  padding: 10px 20px;
}

.notification-drp .dropdown-menu .dropdown-item.new::after {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #c3262d;
  border-radius: 7px;
  position: absolute;
  top: 24px;
  left: 18px;
}

.notification-drp .dropdown-menu .dropdown-item .noti-time {
  display: block;
  color: #888;
  font-size: 70%;
  margin-top: 5px;
}

.globalSearch {
  margin-left: 80px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .globalSearch {
    margin-left: 30px;
    display: none;
  }
}

.globalSearch input {
  border-radius: 50px;
  width: 30rem;
  height: 50px;
  border: 0;
  background-color: #f5f5f5;
  padding-left: 30px;
  padding-right: 60px;
}

@media screen and (max-width: 1199px) {
  .globalSearch input {
    width: 20rem;
  }
}

.globalSearch input:focus {
  background-color: #f5f5f5;
}

.btn-search {
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 10px;
  border: 0;
}

.btn-search:focus,
.btn-search:active {
  outline: 0;
  border: 0;
}

.adBanner {
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.adBanner img {
  -o-object-fit: center;
  object-fit: center;
  height: 100%;
  width: 100%;
}

.nav-tabs .nav-link {
  color: #16294a;
  border: 0;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #c3262d;
  font-weight: 500;
  border-color: #c3262d;
}

.btn-animate {
  background-color: black;
}

.btn-animate :hover {
  background-color: #c1282d;
}

.requireField {
  border: 2px solid #c1282d !important;
}

.pointer {
  cursor: pointer;
}

.text-red {
  color: #c3262d;
}

.text-gray {
  color: #959595;
}

.bg-red {
  background: #c3262d;
}

.pizzaBtn>.form-check-input {
  height: 1.5em !important;
  width: 3em !important;
}

.pizzaBtn1>.form-check-input {
  height: 1.5em !important;
  width: 3em !important;
  margin-left: 60px;
}

.pizzaBtn {
  /* width: 3em !important; */
  display: inline-block;
  margin-left: 10px;
}

.bold {
  font-weight: 600;
}

.react-calendar__tile--active {
  background: none !important;
}

.react-calendar button {
  border: 1px solid gainsboro !important;
}

.react-calendar {
  border: 1px solid gainsboro !important;
  border-radius: 25px;
  padding: 10px;
  /* width: 100% !important; */
  /* margin-right: 10px; */
  box-shadow: 4px 6px 8px -2px rgba(0, 0, 0, 0.3);
}

.react-calendar__navigation button {
  border: none !important;
}

.react-calendar__tile--active abbr {
  padding: 5px !important;
  background: #c3262d !important;
  border-radius: 50px !important;
}

.react-calendar__tile--now {
  border: none !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--selected {
  background-color: #c3262d !important;
  color: #ffffff !important;
}

.TablePagination p {
  margin-bottom: 0px;
}

.modal .modal-header .modal-title {
  font-size: 1.1rem;
}

.modal-title {
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: bold;
}

.form-group label,
.form-label {
  font-weight: 600;
  margin-bottom: 5px;
}

@media screen and (max-width: 1199px) {

  .form-group label,
  .form-label {
    font-size: 14px;
  }
}

.modal-body {
  padding: 20px 30px;
}




.react-datepicker__close-icon::after {
  background-color: white !important;
  color: #c3262d !important;
  font-size: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdt_Table {
  min-height: 532px;
}



.qty-controls>.btn {
  width: 30px;
  line-height: normal;
  color: #ffffff;
  margin: 1px;
  padding: 4px 10px;
}


.custome-eye {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

/* nt;
  color: #c3262d !important;
  font-size: 30px !important;
}
 */






.react-datepicker__close-icon::after {
  background-color: white !important;
  color: #c3262d !important;
  font-size: 30px !important;
}


/* nt;
  color: #c3262d !important;
  font-size: 30px !important;
} */


.rdt_TableHead .rdt_TableCol {
  font-weight: bold;
  font-size: 16px;
}


.cart-container {
  max-height: 320px !important;
  overflow-y: auto !important;
}




/* nt;
  color: #c3262d !important;
  font-size: 30px !important;
} */



.items-container {
  border-radius: 10px;
  background-color: #f9f9f9;
  padding: 15px;
  font-size: 14px;
}


.colorBox {
  height: 30px;
  width: 40px;
  border-radius: 5px;
}





/* nt;
  color: #c3262d !important;
  font-size: 30px !important;
} */



.addItem-modal {
  box-shadow: 0 0 300px 300px rgba(0, 0, 0, 0.5);
  height: calc(100% - 100px);
  margin-top: 3rem;
}



.rdt_TableCell {
  font-size: 16px;
}




.address-container {
  border-radius: 10px;
  border: 1px solid #ced4da;
  padding: 20px;
}

.address-container .border-bottom:last-child {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.add-more_Address {
  margin-top: 15px;
  margin-left: auto;
  display: block;
}

.password-field {
  position: relative;
}

.password-field i {
  position: absolute;
  right: 10px;
  top: 11px;
}

.react-datepicker__navigation--years-upcoming,
.react-datepicker__navigation--years-previous {
  top: unset !important;
}

.react-datepicker__navigation--years {
  background-color: #DDD !important;
  height: 28px !important;
  width: 100% !important;
  border: 0 !important;
}

.react-datepicker__navigation--years-upcoming::before {
  content: "\f106";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #000000;
  font-size: 18px;
  text-indent: 0;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%);
}

.react-datepicker__navigation--years-previous::before {
  content: "\f107";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #000000;
  font-size: 18px;
  text-indent: 0;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%);
}

.rs-input:focus,
.rs-input:hover:not(:disabled) {
  border-color: none
}

.rs-input:focus {
  outline: none !important;
}

.pac-container {
  z-index: 9999 !important;
}

a.dropdown-item.searchLocationClass:hover {
  text-decoration: none;
  background-color: #ecf1f5;
}