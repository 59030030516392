* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.form-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.see-all {
  color: #c3262d;
  font-weight: bold;
}

/* .order-search, */
.prm-search {
  position: absolute;
  top: -65px;
  right: 0;
}

@media screen and (max-width: 1240px) {
  .order-search {
    /* position: static; */
    margin-top: 16px;
  }
}

.rs-picker-menu {
  z-index: 99999 !important;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  overflow: hidden;
}

.custom-file-upload {
  position: relative;
}

.custom-file-upload input {
  width: 100%;
  height: 200px;
  opacity: 0;
  z-index: 999;
  position: relative;
}

.custom-file-upload label {
  width: 100%;
  height: 200px;
  border: 2px solid #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploader-prev {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploader-prev::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}
.custom-file-upload:hover .uploader-prev::after {
  top: 0;
}
.custom-file-upload:hover .replace-btn {
  display: block;
}

.replace-btn {
  position: absolute;
  z-index: 99;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: none;
  transition: all 0.6s;
}
.eye-location {
  position: absolute;
  top: 30px;
  right: 10px;
}

.modal{
  background-color: rgba(0, 0, 0, 0.4);
  padding-right: 0 !important;
}


.qty-controls > input{
  width: 50px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 14px;
}